import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Senior Project Award",
    years: "2021",
    content:
      "Earned MT2 Best Achievement Award for academic year 2020-2021.",
  },
  {
    id: 2,
    title: "Bachelor's Degree",
    years: "2017 - 2021",
    content:
      "I graduated from the American University of Beirut (AUB) and earned my BS on 2021.",
  },
];

const experienceData = [
  {
    id: 1,
    title: "Software Engineer @ Scryptech",
    years: "Nov. 2019 - Present",
    content:
      "Delivered several web and mobile applications. Worked mainly with Django, Flutter, and ReactJS.",
  },
  {
    id: 2,
    title: "Software Engineer @ HotelMap",
    years: "Jun. 2021 - Present",
    content:
      "Worked on an existing web application built using Flask and ReactJS and delivered a new project based on getting data from a large database and scraping that large set of websites.",
  },
  {
    id: 3,
    title: "Co-Founder @ Incoding",
    years: "Jul. 2021 - Present",
    content:
      "Launched Incoding and delivered several web & mobile applications. Web applications were built using .Net 6 and mobile applications were done using Flutter.",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education}/>
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
