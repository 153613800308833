import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function Portfolio({ portfolio }) {
  const { category, title, image, popupLink, link } = portfolio;
  const [toggler, setToggler] = useState(false);


  const handleIcon = () => {
      return <i className="icon-link"></i>;
  
  };
  return (
    <>
      <a
        href={link ? link : "!#"}
        target="_blank"
        rel="noopener noreferrer"
        className="work-image"
      >
        <div className="portfolio-item rounded shadow-dark">
          <div className="details">
            <span className="term text-capitalize">{category}</span>
            <h4 className="title">{title}</h4>
            <span className="more-button">{handleIcon()}</span>
          </div>
          <div className="thumb">
            <img src={image} alt="Portfolio-title" />
            <div className="mask"></div>
          </div>
        </div>
      </a>
    </>
  );
}

export default Portfolio;
