import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "Bolby",
  avatarImage: "/images/face_co-2.png",
  content:
    "I am Khalil Al Ahmad, a software engineer from Beirut, Lebanon. I have rich experience in building web and mobile applications. I specialize in Django and ReactJS and have professional experience working with Flutter, Firebase, and Node.js.",
};

const progressData = [
  {
    id: 1,
    title: "Front-End Web Development",
    percantage: 65,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "Back-End Web Development",
    percantage: 90,
    progressColor: "#6C6CE5",
  },
  {
    id: 3,
    title: "Mobile App Development",
    percantage: 80,
    progressColor: "#FF4C60",
  },
];

const counterData = [
  {
    id: 1,
    title: "Projects completed",
    count: 30,
    icon: "icon-fire",
  },
  {
    id: 2,
    title: "Cup of coffee",
    count: 4670,
    icon: "icon-cup",
  },
  {
    id: 3,
    title: "Satisfied clients",
    count: 23,
    icon: "icon-people",
  },
];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content}</p>
                  <div className="mt-3">
                    <a className="btn btn-default" href="/assets/CV.pdf" target="_blank">
                      View CV
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-4 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
